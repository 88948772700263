<template>
    <div class="d-flex align-items-center flex-row-reverse" style="gap: 20px">
        <ek-dialog
            v-if="getUserRoles() == Admin || getUserRoles() == SuperAdmin"
            ref="addDialog"
            title="إضافة وحدة"
            size="md"
            btnText="اضافة وحدة"
            @ok="submit()"
            @close="reset"
        >
            <template #body>
                <ValidationObserver ref="subjectForm">
                    <b-form>
                        <div
                            class="d-flex justify-content-between align-items-center w-100"
                        >
                            <h4>حالة الوحدة</h4>
                            <b-form-radio-group
                                id="radio-group-2"
                                v-model="unitDto.isFree"
                                name="radio-sub-component"
                            >
                                <b-form-radio :value="true">مجاني</b-form-radio>
                                <b-form-radio :value="false"
                                    >مدفوع</b-form-radio
                                >
                            </b-form-radio-group>
                        </div>
                        <ek-input-text
                            :rules="[
                                {
                                    type: 'required',
                                    message: '  اسم الوحدة مطلوب',
                                },
                            ]"
                            name="name"
                            placeholder=" ادخل اسم الوحدة"
                            label="  اسم الوحدة"
                            v-model="unitDto.name"
                        ></ek-input-text>
                        <ek-input-text
                            :rules="[
                                {
                                    type: 'required',
                                    message: '  استاذ الوحدة مطلوب',
                                },
                            ]"
                            name="name"
                            placeholder=" ادخل استاذ الوحدة"
                            label="  استاذ الوحدة"
                            v-model="unitDto.tetcherName"
                        ></ek-input-text>
                        <ek-input-select
                            :rules="[
                                {
                                    type: 'required',
                                    message: '  يرجى اختيار الجامعة',
                                },
                            ]"
                            :options="universityList"
                            name="classes"
                            v-model="unitDto.universityId"
                            placeholder="تابع (الجامعة - كلية - فرع)"
                            label="تابع (الجامعة - كلية - فرع)"
                            clearable
                            @change="resetFaculty"
                        ></ek-input-select>

                        <b-row no-gutters>
                            <b-col lg="6" md="12">
                                <ek-input-select
                                    :options="subjectsNames"
                                    label="تابع ل (سنة - فصل - مادة)"
                                    name="تابع ل (سنة - فصل - مادة)"
                                    placeholder="تابع ل (سنة - فصل - مادة)"
                                    v-model="unitDto.subjectId"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'المادة مطلوبة',
                                        },
                                    ]"
                                >
                                </ek-input-select>
                            </b-col>
                        </b-row>

                        <ek-input-image
                            ref="imgFile"
                            label=" صورة الوحدة "
                            name="صور "
                            @input="uploadSubFile"
                            accept="image/png, image/jpeg"
                        >
                            <h5>انقر لتحميل الصورة</h5>
                        </ek-input-image>
                    </b-form>
                </ValidationObserver>
            </template>
        </ek-dialog>

        <ek-input-text
            v-model="filterDto.search"
            placeholder="ابحث عن وحدة"
        ></ek-input-text>
    </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapState, mapActions, mapGetters } from "vuex";
import { getUserRoles } from "@/app/admin/auth/utils";
import { SuperAdmin, Admin } from "@/router";
export default {
    components: {
        ValidationObserver,
    },
    data: () => ({
        SuperAdmin,
        Admin,
    }),
    computed: {
        ...mapState({
            unitDto: (state) => state.unit.unitDto,
            filterDto: (state) => state.unit.filterDto,
            url: (state) => state.unit.url,
            universityList: (state) => state.university.universityList,
            years: (state) => state.settings.yearList,
            semesters: (state) => state.settings.semesterList,
            branchList: (state) => state.branches.branchList,
            subjectsNames: ({ subjects }) => subjects.subjectsNames,
        }),
        ...mapGetters(["getUrl"]),
    },
    methods: {
        ...mapActions([
            "addSubject",
            "getSettingYear",
            "getSettingSemester",
            "getBranchList",
            "updateUnit",
            "addFile",
        ]),
        getUserRoles,
        submit() {
            this.$refs["subjectForm"].validate().then((success) => {
                if (success) {
                    this.updateUnit({
                        ...this.unitDto,
                        imageUrl: this.url,
                    }).then((data) => {
                        // this.$store.commit("Add_Courses_Subjects", data);
                        console.log(data);
                        this.$store.dispatch("getUnitList", {
                            universityId: "",
                            facultyId: "",
                            yearId: "",
                            semesterId: "",
                            pageIndex: 1,
                            pageSize: 4,
                        });
                        // window.location.reload();
                    });
                    console.log(getUserRoles(), "test");
                    this.$refs.addDialog.close();
                    this.$store.commit("RESET_SUBJECT");
                    this.$refs.subjectForm.reset();
                }
            });
        },

        resetFaculty() {
            this.unitDto.universityId = "";
            this.unitDto.facultyId = "";
        },
        resetDepartment() {
            this.unitDto.facultyId = "";
            this.unitDto.departmentId = "";
        },
        reset() {
            this.$refs.subjectForm.reset();
            this.$refs.imgFile.reset();
            this.$store.commit("RESET_SUBJECT");
            Object.assign(this.unitDto, {
                name: "",
                imageUrl: "",
                subjectId: "",
                isFree: false,
                semesterId: "",
                yearId: "",
                departmentId: "",
                universityId: "",
                facultyId: "",
            });
            this.$refs.addDialog.close();
        },
        uploadSubFile(event) {
            if (event.file) {
                this.addFile({ ...event, folderName: "Profile" });
            }
        },
    },
    created() {
        this.getUserRoles();
        this.getSettingYear();
        this.getSettingSemester();
        this.getBranchList();
    },
    beforeDestroy() {
        this.$store.commit("Reset_Unit");
    },
};
</script>

<style>
#color {
    width: 30px !important;
    height: 30px !important;
}
</style>
